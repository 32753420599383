<template>
<section>
    <v-container>
        <v-row justify="start">
          <v-toolbar-title
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            >
            Registered Homely Remedies List
          </v-toolbar-title>
          <v-spacer />
          <v-btn
              v-if="$can('add')"
              to = "/homelyRemedies/register"
              bottom
              >Register Homely Remedies
          </v-btn>
          <v-spacer />
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
          >
          </v-text-field>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="remedies"
          :options.sync="options"
          :server-items-length="totalRemedies"
          :loading="loading"
          loading-text="Loading ..... Please wait"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 40],
            itemsPerPageText: 'Homely Remedies per page',
          }"
          update:options
          class="elevation-23"
        >
            <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-dialog v-model="dialog" max-width="500px">

                      <v-card>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.name" label="Remedy name"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.directive" label="Directive"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <label for="quantity-order"> Total Quantity</label><br>
                                <input v-model="editedItem.totalQuantity" type="number" name="quantity-order" id="input"/>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.description" label="Description"></v-text-field>
                              </v-col>
                               <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.type" label="Type"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text  @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text  @click="save">Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-row justify="space-around">
                      <span class="group pa-2">
                          <v-icon
                            v-if="$can('update')"
                            small
                            class="mr-2"
                            @click="editItem(item)"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            v-if="$can('delete')"
                            small
                            @click="deleteItem(item)"
                          >
                            delete
                          </v-icon>
                            <v-icon
                              v-if="$can('read')"
                              medium @click="detail_remedies_homely(item)">info</v-icon>
                      </span>
                  </v-row>
                </template>
        </v-data-table>
    </v-container>
</section>
</template>
<script>
import {  mapActions } from 'vuex'
import homelyRemedyApi from '../../services/api/HomelyRemediesApi';
export default {
    name: 'remedyList',
    components: {
    },
    data () {
        return {
            dialog: false,
            remedies: [],
            totalRemedies: 0,
            loading: true,
            options: {},
            search: '',
             headers: [
                { text: 'Homely Remedy Name', value: 'name', class: 'success--text font-weight-bold' },
                { text: 'Directive', value: 'directive' , sortable: false, class: 'success--text font-weight-bold'},
                { text: 'Total Quantity', value: 'totalQuantity', class: 'success--text font-weight-bold' },
                { text: 'Description', value: 'description' , sortable: false, class: 'success--text font-weight-bold'},
                { text: 'Actions', value: 'action', sortable: false, class: 'success--text font-weight-bold' },
            ],
              editedItem: {
                name: '',
                totalQuantity: '',
                directive: '',
                description: '',
                type:'',
                expiryDate: null,
                openDate: null,
                menu1: false,
                menu2: false,
              },
              defaultItem: {
               name: '',
                totalQuantity: '',
                directive: '',
                description: '',
                type:'',
                expiryDate: null,
                openDate: null,
                menu1: false,
                menu2: false,
              },
        }
    },
    methods: {
        ...mapActions([
            'update_homely_remedy',
        ]),
        editItem (item) {
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
          confirm('Are you sure you want to delete this homely remedy?') && this.delete_homely_remedy(item)
          const index = this.remedies.indexOf(item)
          this.remedies.splice(index, 1)
        },

        close () {
          this.dialog = false
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)
        },

        save () {
            delete this.editedItem.home;
            delete this.editedItem.createdOn;
            delete this.editedItem.modifiedOn;
            delete this.editedItem.user;
            this.update_homely_remedy({...this.editedItem})
          this.close()
        },
        get_homely_registered_remedy_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              const { sortBy, sortDesc, page, itemsPerPage } = this.options
              if (sortDesc&& sortDesc.length && sortDesc[0] == false){
                var orde = 'asc'
              }
              else{
                 orde = 'desc'
              }
              if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                var homecode = ''
              }
              else{
                homecode = localStorage.getItem('homeCode')
              }
              homelyRemedyApi.getRegisteredHomelyRemedy({page: page, count:itemsPerPage,home:homecode, q:'', orderBy: sortBy && sortBy[0], order: orde})
                .then(remedies => {
                    this.remedies = remedies.result
                    this.totalRemedies = remedies.count
                })
                .catch(error => {
                    return reject(error)
                })
              setTimeout(() => {
                this.loading = false
                }, 1000)
          })
        },
        delete_homely_remedy(item) {
            homelyRemedyApi.deleteRegisteredHomelyRemedy(item.id)
                .then(remedy => {
                    return ("deleted" + remedy);
                })
        },
        detail_remedies_homely(remediesHomely) {
          this.$router.push({ name: 'remedyHomelyDetail', params: {homeId:remediesHomely.home.id, remediesId:remediesHomely.id}})
        }
    },
    watch: {
        options: {
          handler () {
            this.get_homely_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
          },
          deep: true,
        },
        dialog (val) {
          val || this.close()
        },
        editedItem: function() {
          this.get_homely_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
        },
        search: function() {
          if(this.search.length > 0){
                if (localStorage.getItem('role') == 'Admin' || localStorage.getItem('role')== 'Administrator'){
                var homecode = ''
              }
              else{
                 homecode = localStorage.getItem('homeCode')
              }
              return new Promise((resolve, reject) => {
                  homelyRemedyApi.getRegisteredHomelyRemedy({page:'', count:'',home:homecode, q:this.search, orderBy:'', order: ''})
                    .then(remedy => {
                        this.remedies = remedy.result
                        this.totalRemedies = remedy.count
                    })
                    .catch(error => {
                        return reject(error)
                    })
              })
          }
          if(this.search.length <= 0){
            this.get_homely_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
          }
        },
    },

}
</script>
<style lang="scss">
  #input {
    width:70%;
  }
</style>
